import React, { useState, useEffect, useMemo } from "react";
// import { SearchGridDesign } from "../widgets/tiles/SearchGridDesign";
import { WishlistGridDesignNew } from "../widgets/tiles/WishlistGridDesignNew";
import { useLocation, useHistory } from "react-router-dom";
import { BsLightningFill } from "react-icons/bs";
import {  FaThList } from "react-icons/fa";
import axios from "axios"
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError,CompareDiamond } from "../Popup";
import { Skeleton } from "@material-ui/lab";
import ModifiedSearchOffCanvasGrid from "./ModifiedSearchOffCanvasGrid";
import { SwipeableDrawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_helpers";
import { NODE_API_URL } from "../../../env_config";
export default function WishlistGrid({conversionrate,symbol,language,isprofilecomplete,symbolname}) {
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const [infiniteloader,setinfiniteLoader] = useState(false)
    const location = useLocation();
    const history = useHistory();
    const [StockAPI, setStockAPI] = useState(location.state.searchresult);
    const [StockCount,setStockCount] = useState(0);
    const [dimondType,setDiamondType] =useState(StockAPI.length > 0 && StockAPI[0].diamond_type? StockAPI[0].diamond_type:"N")
    const [loader,setLoader] = useState(true)
    const [modifymsg,setModifyMessage] = useState("")
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [pagecount, setPageCount] = useState(10);
    const stoneCount = useMemo(() => localStorage.getItem("stoneCount"), []);
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };
      const [totalcarat,setTotalCarat] = useState(0)
      const [totalinrcar,setTotalInrCar] = useState(0)
      const [diamondcount,setDiamondcount] = useState(0)
      const [totalourprice,setTotalOurPrice] = useState(0)
      useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selected.map(value => {
            const finddata = StockAPI.find(element => element.id.toString() === value.toString())
            if(finddata){
                temptotalcarat += finddata.C_Weight
                temptotalinrcar += (selected.length>0?finddata.OurRate/selected.length:0)
                temptotalourprice += finddata.OurPrice
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selected.length>0?(temptotalourprice/temptotalcarat * conversionrate):0)
        setTotalOurPrice(temptotalourprice * conversionrate)
    },[selected])
    const [updatewishlist,setUpdateWishlist] = useState()
    const [wishlistdata,setWishlistData] = useState([])
    useEffect(() => {
        const finalData = {
            user_id: user.CustomerId,
            page:page + 1
        };
        if(page > 0){
            axios({
                method: "POST",
                url: `${NODE_API_URL}/AUS/fetchWishlist`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        // setData(res.data)
                        setStockAPI([...StockAPI,...res.data.data]);
                        // res.data[0].map(value => {
                        //     if (value.stone_status === "Inacti"){
                        //         setIsActive(false)
                        //     }
                        // })  
                    }
                })
                .catch((err) => {
                });
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/AUS/fetchWishlistCount`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                if (res && res.data && res.data.count) {
                    // setData(res.data)
                    setStockCount(res.data.count);
                    // res.data[0].map(value => {
                    //     if (value.stone_status === "Inacti"){
                    //         setIsActive(false)
                    //     }
                    // })  
                }
            })
            .catch((err) => {
            });
    }, [updatewishlist,page]);
    // useEffect(() => {
    //     // if(items.length !== StockAPI.length){
    //     fetchData(page);
    //     // }
    // }, [page]);

    const fetchData = (page) => {
        setinfiniteLoader(true)
        setTimeout(function() { 
            setItems([
                ...items,
                ...StockAPI.slice((page - 1) * pagecount, page * pagecount),
            ]);
        setinfiniteLoader(false)

        }, 2000);
    };
    //  console.log(items,"items")

    const onScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight &&
                StockCount !== StockAPI.length
        ) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [StockAPI]);

    const ChangeView = () => {
        history.push({
            pathname: "/wishlist",
            // state: StockAPI,
            state: {
                searchresult:StockAPI,
                oldvalues:location.state.oldvalues
            }
            // state: decompress(res.data.ProductDetails),
        });
    };

    const DetailSemiAprrove = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    const profileSwalBuy = () => {
        // setSemiAprroveModal(true)
        setSwalErrorOpen(true)
        setAlertMessage("Please complete your profile to buy more diamonds")
    }
    const profileSwalHold = () => {
        // setSemiAprroveModal(true)
        setSwalErrorOpen(true)
        setAlertMessage("Please complete your profile to hold more diamonds")
    }

    const modifiedSearch = () => {
        const searchLink =
            StockAPI[0].diamond_type === "L"
                ? "/labsearchdiamond"
                : "/searchdiamond";
        history.push({
            pathname: searchLink,
            state: StockAPI,
            // state: decompress(res.data.ProductDetails),
        });
    };
    // console.log(wishlistdata,"wishlistdata")
    const [diamondselected,setDiamondSelected] = useState([])
    const [buypopup,setBuyPopUp] = useState(false)
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [comparePopup,setComparePopup] = useState(false)
    const CompareDiam = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        if(selectedFlatRows.length === 1){
            alert("Please Select Minimum 2 Diamonds")
            return
        }
        if(selectedFlatRows.length > 4){
            alert("Only 4 diamonds allowed")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let inactive = false
       const selectedvalues = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            if(finddata.stone_status === 0){
                inactive = true
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }
        else{
            setDiamondSelected(selectedvalues)
        setComparePopup(true)
        setDiamondcount(diamondcount)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
        }
      }
    const BuyDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let inactive = false
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
            // if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            // }
            if(finddata.stone_status === 0){
                inactive = true
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }
        else{
            setDiamondSelected(selectedvalues)
            setBuyPopUp(true)
            setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
            setDiamondcount(diamondcount)
            setBuyPopUpTotal(temptotalourprice)
            // console.log(selectedvalues,"selectedvalues")
        }
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let inactive = false
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            if(finddata.stone_status === 0){
                inactive = true
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }
        else{
            setDiamondSelected(selectedvalues)
            setHoldPopUp(true)
            setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
            setDiamondcount(diamondcount)
            setBuyPopUpTotal(temptotalourprice)
            // console.log(selectedvalues,"selectedvalues")
        }
      }
      const [swipeableDrawer, setSwipeableDrawer] = useState({
        right: false,
      });
  
      const toggleDrawer = (side, open) => event => {
          if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
      
          setSwipeableDrawer({ ...swipeableDrawer, [side]: open });
        };
    return (
        <>
        {StockAPI.length > 0 ?
            <div
                className={`card card-custom card-sticky gutter-b ${
                    StockAPI.length > 0 && StockAPI[0].diamond_type === "L" ? "labTab" : "natTab"
                }`}
            >
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {StockCount} Wishlist Stones {" "}
                        </span>
                        <span className="text-muted font-weight-bold font-size-sm">
                        {language.SEARCHLIST_TOTALDIAMONDSELECTED}: {selected.length} {language.SEARCHLIST_DIAMONDS}  |  {language.SEARCHLIST_TOTALCARAT} :{totalcarat.toFixed(2)}  |  Total INR/CT: {symbol}{totalinrcar.toFixed(2)}  |  {language.SEARCHLIST_TOTALPAYABLE}: {symbol}{totalourprice.toFixed(2)}
                        </span>
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        {/* {selected.length >= 4 && !isprofilecomplete ?(
                            <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => profileSwalBuy() }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                        ):(
                            )} */}
                            <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => BuyDiamond(selected) }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                        {/* {selected.length >= 4 && !isprofilecomplete ?(
                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => profileSwalHold() }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                        ):(
                            )} */}
                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => HoldDiamond(selected) }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => CompareDiam(selected) }>{language.DIAMOND_COMPARISION_BTN}</button>

                        <button type="button" className="btn btn-icon btn-light-primary mr-2 text-nowrap" onClick={(e) => ChangeView()} ><FaThList /></button>
                        <SwipeableDrawer
                                    anchor="right"
                                    open={swipeableDrawer.right}
                                    onClose={toggleDrawer('right', false)}
                                    onOpen={toggleDrawer('right', true)}
                                >
                                    {/* {sideList('right')} */}
                                    <ModifiedSearchOffCanvasGrid language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage}/>
                                </SwipeableDrawer>
                        {/* <button type="button" className="btn btn-icon btn-light-primary mr-2 text-nowrap" onClick={(e) => ChangeView()}><FaColumns /></button> */}
                        <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
                        <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
                        <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
                        <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} closeBtn={true}/> 
 
                        <CompareDiamond onHide={() => setComparePopup(false)} show={comparePopup} diamondselected={diamondselected} language={language} symbol={symbol}/>
                    </div>
                </div>
                <div className="card-body p-2">

                <div className="d-flex flex-wrap justify-content-between gridData">
                        {StockAPI.map((val,index) => {
                            const isItemSelected = isSelected(val.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <WishlistGridDesignNew
                                    // shape={val.SHAPE}
                                    key={val.id}
                                    {...val}
                                    isItemSelected={isItemSelected}
                                    labelId={labelId}
                                    handleClick={handleClick}
                                    conversionrate={conversionrate}
                                    symbol={symbol}
                                    wishlistdata={wishlistdata}
                                    setUpdateWishlist={setUpdateWishlist}
                                    language={language}
                                    HoldDiamond={HoldDiamond}
                                    BuyDiamond={BuyDiamond}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>:!infiniteloader?
        <div className="card custom-card gutter-b">
        <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
            <div className="text-center">
                <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>{location.state.modifymsg}</strong></div>
                <div class="mt-3 text-muted">Please Modify Your Search</div>
                {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
                <SwipeableDrawer
                    anchor="right"
                    open={swipeableDrawer.right}
                    onClose={toggleDrawer('right', false)}
                    onOpen={toggleDrawer('right', true)}
                >
                    {/* {sideList('right')} */}
                    <ModifiedSearchOffCanvasGrid language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage}/>
                </SwipeableDrawer>
                {location.state.oldvalues?<button className="btn btn-primary mt-5 px-8" onClick={toggleDrawer('right', true)}>{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}
            </div>
        </div>
    </div>:""}
        {infiniteloader?<div className="card-body pt-5 pb-0">
                    <div className="d-flex flex-wrap justify-content-between gridData">
                        {Array.from(Array(12), (e, i) => {
                            return (
                                <div className="mb-5 px-2" style={{width:"280px"}} key={i}>
                                    <div className="row m-0 card rounded-0">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center" >
                                            <Skeleton variant="rect" height={220} width={280} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={60} />
                                            <Skeleton variant="text" width={60} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex px-2 align-items-center">
                                            <span className="mr-3"><Skeleton variant="circle" width={35} height={35} /></span>
                                            <Skeleton variant="text" width={200} height={30}/>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="rect" width={100}  height={15}/>
                                            <Skeleton variant="rect" width={100}  height={15}/>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={80} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 mb-2">
                                            <Skeleton variant="text" width={180} />
                                            <Skeleton variant="text" width={140} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                            <Skeleton variant="rect" width={160} height={40} />
                                            <Skeleton variant="rect" width={70} height={40}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>:""}
                {StockCount === StockAPI.length?<div className="d-flex align-items-center justify-content-center">No more stones found</div>:""}
        </>
    );
}
