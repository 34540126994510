import React, { useState } from "react";
import { RecentDiamondCard } from "./RecentDiamond/RecentDiamondCard";
// import { Tracklist } from "./tracklist/Tracklist";
import axios from "axios";
import { NODE_API_URL,BASE_URL } from "../../../../env_config";
import { Skeleton } from "@material-ui/lab";
import Cookies from "js-cookie"
export const RecentDiamondTile = (prop) => {
    const [loader,setLoader]=useState(true)
    const [data, setData] = React.useState([]);
    const [updaterecently,setUpdateRecently] = React.useState()
    function getData() {
        axios({
            method: "GET",
            url: `${NODE_API_URL}/AUS/recently-viewed-diamonds`,
            headers: { 
                "Content-Type": "application/json" ,
              "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
              }
        })
            .then((res) => {
                // console.log("data", res);
                if (res && res.data && res.data.data) {
                    setLoader(false)
                    setData(res.data.data);
                }
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
    }
    // console.log(data[0],"datadata")
    React.useEffect(() => {
        getData();
    }, [updaterecently]);


    return (
        <>
            {/* {console.log("dataaaa", data.length)} */}
            <div className={`card card-custom gutter-b bg-white`}>
                <div className="card-body recentlyViewedDash">
                    <span className="small_title">
                        {prop.language.DASHBOARD_RECENTLYVIEWED}
                    </span>
                    {!loader?
                        <>
                            {!data.length? (
                                <div className="d-flex flex-nowrap justify-content-center align-items-center customRecently my-10 pb-4">
                                    No Records Found
                                </div>
                            ) : (
                                <div className="d-flex flex-nowrap customRecently custom-scrollbar my-10">
                                    {/* <div className="custom-scrollbar"> */}
                                        {data
                                            ? data.slice(0, 4).map((val) => {
                                                return (
                                                    <RecentDiamondCard
                                                        key={val.id}
                                                        {...prop}
                                                        {...val}
                                                        setUpdateRecently={setUpdateRecently}
                                                    />
                                                );
                                            })
                                            : null}
                                    {/* </div> */}
                                </div>
                            )}
                        </>
                    : 
                        <div className="d-flex flex-nowrap customRecently custom-scrollbar my-10">
                            {Array.from(Array(4), (e, i) => {
                                return (
                                <div className="mb-5 px-2" style={{width:"280px"}} key={i}>
                                    <div className="row m-0 card rounded-0">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center" >
                                            <Skeleton variant="rect" height={220} width={280} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={60} />
                                            <Skeleton variant="text" width={60} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex px-2 align-items-center">
                                            <span className="mr-3"><Skeleton variant="circle" width={35} height={35} /></span>
                                            <Skeleton variant="text" width={200} height={30}/>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={100}  height={30}/>
                                            <Skeleton variant="text" width={100}  height={30}/>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={80} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 mb-2">
                                            <Skeleton variant="text" width={180} />
                                            <Skeleton variant="text" width={140} />
                                        </div>
                                    </div>
                                </div>
                                );
                            })}
                        </div>
                    }
                </div>
            </div>
        </>
    );
};
